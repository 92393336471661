export function apiPaths(projectType){

  const fields = {
    emo: 'electric-motor-optimization',
    pfp: 'power-factor-penalties',
    roi: 'roi',
    roles: 'roles',
    users: 'users',
    shippingoption: 'shippingoption',
    categories: 'category',
    products: 'product',
    productself: 'productself',
    ordermanagement: 'ordermanagement'
}

    return fields[projectType]
}

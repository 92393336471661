import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss"
import PaginationDropDown from "components/Dropdowns/PaginationDropdown/PaginationDropdown.js";
import CardFilterByStatus from "components/Cards/CardFilterByStatus/CardFilterByStatus";
import { useListTable } from "hooks/useListTable";
import { CardListTable } from "components/Cards/CardListTable/CardListTable";
import { PaginationButtons } from "components/Buttons/PaginationButtons";
import { HeaderListTable } from "components/Headers/HeaderListTable";

export default function ListTable({color,numbers,filterable}) {
    const {
        data,
        button,
        handleFilter,
        handleSort,
        handlePagination,
        setRowsPerPage,
        handleHistoryPush,
        pages,
        page,
        fields,
        title,
        selectedFilter,
        isProducts
    } = useListTable()

    
  return (
    <div id={styles.emo}>
        {
            filterable 
            &&
            <CardFilterByStatus 
            selected={selectedFilter()} 
            handleFilter={handleFilter} 
            color={color} 
            numbers={numbers} 
            products={isProducts}
            />
        }
        <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 px-4">
                <div
                    className={
                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                    (color === "light" ? "bg-white" : "bg-blue-900 text-white")
                    }
                >
                    <HeaderListTable title={title} color={color} button={button} />
                    <CardListTable fields={fields} color={color} handleSort={handleSort} data={data} handleHistoryPush={handleHistoryPush}/>
                    <PaginationButtons pages={pages} page={page} handlePagination={handlePagination} />
                    <PaginationDropDown end={setRowsPerPage}/>
                </div>
            </div>
        </div>
    </div>
  );
}

ListTable.defaultProps = {
    color: "light",
};
  
ListTable.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
  };
  
import React from "react";

export function CardListTable({fields, data, handleSort, color, handleHistoryPush}){
    return(
        <div className="block w-full overflow-x-auto">
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr>
                                    {
                                        fields.map(({name,label})=>(
                                            <th
                                                className={
                                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                                    (color === "light"
                                                    ? "bg-gray-100 text-gray-600 border-gray-200"
                                                    : "bg-blue-800 text-blue-300 border-blue-700")
                                                    
                                                }
                                                onClick={()=>handleSort(name)}
                                                >
                                                {label}
                                                </th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                            data?.length>0&&data.map((row)=>(
                                <tr key={row.id} onClick={()=>handleHistoryPush(row.id)} >
                                    {
                                        fields.map(({name})=>{
                                            if(name==="id"){
                                                return <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                                    <span
                                                        className={
                                                        "ml-3 font-bold " +
                                                        +(color === "light" ? "text-gray-700" : "text-white")
                                                        }
                                                    >
                                                        {row[name]}
                                                    </span>
                                                </th>
                                            }else{
                                                return <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                                            {
                                                            name==='active'
                                                            ? 
                                                                row[name]
                                                                ? 
                                                                <i className="fas fa-check"></i> 
                                                                :
                                                                <i className="fas fa-times"></i>
                                                            :
                                                            row[name]
                                                            }
                                                        </td>
                                                }
                                        })
                                    }

                            </tr>
                            ))    
                            
                            }
                            </tbody>
                        </table>
                    </div>
    )
}
export function projectLineValidator(aux,linesFields){
    let errors= []
    let errorCount=0
    aux.lines = aux.lines.map((line)=>{
      let error = {}
      for (const key in line) {
        linesFields.forEach(field => {
          if(field.name === key){
            if(field.validation){
              const validation= field.validation(line[key],line)
              if(!validation.validate){
                error[key] = validation.errorInfo
                errorCount++;
              }
            }
          }
        });
      }
      errors.push(error)
      return line
    })
    return {
        aux,
        errors,
        errorCount
    }
}
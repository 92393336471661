import React from "react";

export function PaginationButtons({handlePagination,pages, page}){
    return(
        <div className="py-2 flex justify-center">
            <nav className="block">
                <ul className="flex pl-0 rounded list-none flex-wrap">
                <li>
                    <button
                    onClick={()=>handlePagination("left")}
                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-green-500">
                    <i className="fas fa-chevron-left -ml-px"></i>
                    </button>
                </li>
                {
                    pages>0&&[...Array(pages)].map((e,i)=>(
                            <li key={i}>
                                <button
                                className={page==i+1?
                                    "first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 text-white bg-green-400"
                                :
                                "first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-green-500"
                                }                                               
                                onClick={()=>handlePagination(i+1)}
                                >
                                {i+1}
                                </button>
                            </li>
                    ))
                }
                <li>
                    <button
                    onClick={()=>handlePagination("right")}
                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-green-500">
                    <i className="fas fa-chevron-right -mr-px"></i>
                    </button>
                </li>
                </ul>
            </nav>
    </div>
    )
}
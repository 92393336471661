import React from "react";

export function HeaderListTable({title, color,button}){
    return(
         <div className="rounded-t mb-0 px-4 py-3 border-0">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3
                                    className={
                                    "font-semibold text-lg " +
                                    (color === "light" ? "text-pink-900" : "text-white")
                                    }
                                >
                                    {title}
                                </h3>
                            </div>
                            {
                                button &&
                                <button
                                    className="bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={()=>button.onClick()}
                                >
                                    {button.title}
                                </button>
                            }
                        </div>
                    </div>
    )
}
export function buttonTableCreator(projectType, onClick){

  const button = {
    emo: {
      title:"CREATE"
    },
    pfp: {
      title:"CREATE"
    },
    roi: {
      title:"CREATE"
    },
    roles: {
      title:"CREATE"
    },
    users: {
      title:"CREATE"
    },
    shippingoption: {
      title:"CREATE"
    },
    categories: {
      title:"CREATE"
    },
    products: {
      title:"CREATE"
    },
    productself: {
      title:"CREATE"
    },
    ordermanagement: false
}
    if(button){
      button.title = button[projectType].title
      button.onClick = onClick
      return button
    }
    return false
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss"

// components


export default function CardRolesTable({ color, permissions, change, settings }) {

  const[disabled, setDisabled] = useState(true)
  const[refresh, setRefresh] = useState(false)


  function handleInputChange(e,i){
    let aux=permissions
    aux[i][e.target.name] = e.target.value
    let event = {
      target:{
        name:"permissions",
        value:aux
      }
    }
    change(event)
  }

  function handleDelete(i){
    let aux = permissions.filter((e,ind)=>ind!==i)
    let event = {
      target:{
        name:"permissions",
        value:aux
      }
    }
    change(event)
  }

  function handleEdit(){
    setDisabled(!disabled)
  }

  function handleAdd(){
    let event = {
      target:{
        name:"permissions",
        value:[...permissions,{entity:"product",type:"WRITE"}]
      }
    }
    change(event)
  }

  let entities =[
  { id: "product", name: "Product" },
  { id: "product.self", name: "Product (Self)" },
  { id: "category", name: "Product Category" },
  { id: "project", name: "Power Factor Penalties and Energy Motors Optimization" },
  { id: "role", name: "User Role" },
  { id: "order", name: "Orders (All)" },
  { id: "order.self", name: "Orders (Self)" },
  { id: "shippingoption", name: "Shipping Options" },
  { id: "pagecontent", name: "Page Content" }]

  let CRUD = [
    "UPDATE","WRITE","READ","DELETE"
  ]
  
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-blue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-gray-800" : "text-white")
                }
              >
                Permissions
              </h3>
            </div>
            <button
                className="bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleAdd}
                style={{display:!settings?"none":"block"}}
            >
                Add
            </button>
            
          </div>
        </div>
        <div  id={styles.tableContainer} className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "w-20 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Section
                </th>
                <th
                  className={
                    "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Permission
                </th>
                
              </tr>
            </thead>
            <tbody>
              {
                permissions && permissions.length > 0&& permissions.map( (d,i)=>{          
                
                return (
              <tr key={i} >

                <td  className=" border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left flex items-center">
                  <select
                    disabled={!settings}
                    type="text"
                    name="entity"
                    value={d.entity}
                    onChange={(e)=>handleInputChange(e,i)}
                    className="px-3 py-3 placeholder-gray-200 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  >
                    {
                      entities.map((e)=>(
                        <option value={e.id}>{e.name}</option>
                      ))
                    }
                  </select>
                </td>

                <td className=" border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap ">
                <select
                    disabled={!settings}
                    type="text"
                    name="type"
                    value={d.type}
                    onChange={(e)=>handleInputChange(e,i)}
                    className="px-3 py-3 placeholder-gray-200 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  >
                    {
                      CRUD.map((e)=>(
                        <option value={e}>{e}</option>
                      ))
                    }
                  </select>
                </td>
                <td >
                  <button
                      className="text-red font-bold uppercase text-xs px-4 py-2 rounded  hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={()=>handleDelete(i)}
                      disabled={!settings}
                  >
                      <i class="far fa-times-circle text-red-500"></i>
                  </button>
                </td>
              </tr>
                )
              }
              )
              }
              

            </tbody>
          </table>
        </div>
      </div>
   </>
  );
}

CardRolesTable.defaultProps = {
  color: "light",
};

CardRolesTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

import React, { useEffect, useState } from "react";

// components

import axios from "axios";
import { useParams } from "react-router";
import CardOrderManagementCrud from "components/Cards/CardOrderManagementCrud/CardOrderManagementCrud";


export default function OrderManagementCrud({setNotifications}) {
  const [order, setOrder] = useState(null)
  const {id} = useParams()

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API+"/ordermanagement/"+id)
    .then((d)=> setOrder(d.data))
  },[id])

  useEffect(()=>{
    return ()=>{
      setNotifications()
    }
  },[])


  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardOrderManagementCrud order={order} />
        </div>
      </div>
    </>
  );
}

import React from "react";

import { useProjectCrud } from "hooks/useProjectsCrud";
import { SendProjectQuotationsButtons } from "components/Buttons/SendProjectQuotationsButtons";
import CardDateQuotes from "components/Cards/CardDateQuotes/CardDateQuotes";
import CardCustomer from "components/Cards/CardCustomer/CardCustomer";
import { ModalCrud } from "components/Modals/ModalCrud";
import { HeaderProjectCrud } from "components/Headers/HeaderProjectCrud";
import { CardProjectInfo } from "components/Cards/ProjectsCards/CardProjectInfo";
import CardLinesTable from "components/Cards/ProjectsCards/CardLinesTable";


export default function ProjectCrud({setNotifications}) {

  const {
    handleChange,
    handleLineChange,
    handleDelete,
    handleSettings,
    handlePreviewQuotation,
    handleQuotation,
    handleSeeDate,
    handleCustomerChange,
    setShowModal,
    showModal,
    input,
    seeDate,
    settings,
    projectType,
    fields,
    linesFields,
    lineErrors
  } = useProjectCrud ({setNotifications})

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <HeaderProjectCrud 
          title={projectType ? "EMO" : "EB" } 
          id={input.id} 
          handleSettings={handleSettings} 
          settings={settings} 
          setShowModal={setShowModal}
        />
        <CardProjectInfo
          fields={fields} 
          input={input} 
          settings={settings} 
          handleChange={handleChange} 
        />
        
        {
          showModal 
          && 
          <ModalCrud 
            showModal={showModal} 
            setShowModal={setShowModal} 
            handleDelete={handleDelete} 
          />
        }
      </div>
      {
        input.lines 
        &&
        <CardLinesTable
          fields={linesFields}
          lines={input.lines} 
          settings={settings} 
          handleLineChange={handleLineChange} 
          setShowModal={setShowModal}
          lineErrors={lineErrors}
        />
      }
      {
        input.quotationInformation
        && 
        <CardCustomer 
          quotationInformation={input.quotationInformation} 
          settings={settings}  
          handleCustomerChange={handleCustomerChange} 
        />
      }
      {
        seeDate?.length>0 
        && 
        <CardDateQuotes 
          quotationDates={seeDate} 
          handleSeeDate={handleSeeDate} 
        />
      }
      <SendProjectQuotationsButtons
        handleQuotation={handleQuotation}
        handlePreviewQuotation={handlePreviewQuotation} 
      />
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { createPopper } from '@popperjs/core';
import { collection, deleteDoc, doc, getDocs, getFirestore, setDoc } from "@firebase/firestore";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "@firebase/storage";

// components

export default function CardProductSelfCrud({products}) {

  const history = useHistory()

  const[input, setInput] = useState({})

  function handleChange(e){
    setInput({...input,[e.target.name]:e.target.value})
  }

  const [settings, setSettings] = useState(false)

  function handleSettings(){
    if(settings) handleSubmit()
    setSettings(!settings)
  }

  const db = getFirestore()
  
  async function handleVideos(id){
      const clientsRef = collection(db, `products/${id}/videos`)
      const querySnapshot = await getDocs(clientsRef);
      const arr = []
      querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          arr.push({...doc.data(), id:doc.id})
      });
      arr.length>0 && setVideos(arr)
  }

  useEffect(()=>{
    if(products!==null){
      let margin = 0 
      if(products.price){
        margin = ((products.price - products.fobPrice)*100)/products.fobPrice 
      }
      setSettings(false)
      setInput(
        {
          id:products.id,
          createdAt:products.createdAt.slice(0,10), 
          name: products.name,
          fobPrice:products.fobPrice,
          active:products.active,
          price: products.price,
          discount:products.discount,
          description:products.description,
          categories:products.categories,
          images: products.images,
          attachments:products.attachments,
          margin: Number(margin.toFixed(0))
        }
      )
      handleVideos(products.id)
    }else{
      setInput({...input,images:[],attachments:[],categories:[]})
      setSettings(true) 
    }
  },[products])

  async function handleSubmit(){
    const storage = await getStorage()
    if(products){
      let aux  = {...products,...input}
      const response = await axios.put(process.env.REACT_APP_API+"/product/"+products.id,aux)
      const clientsRef = collection(db, `products/${products.id}/videos`)
      if(newVideos.length>0){
        newVideos.forEach(async(nVideo)=>{
          const refe = await ref(storage,`/products/${products.id}/videos/${nVideo.file.name}`)
          const uploadVideo =await uploadBytes(refe, nVideo.file)
          const url = await getDownloadURL(uploadVideo.ref)
          const videoObj = {
            url,
            ref:uploadVideo.metadata.fullPath
          }
          await setDoc(doc(clientsRef), videoObj);
        })
      }

      if(deleteVideos.length > 0) {

        deleteVideos.forEach(async (dVideo)=>{
          try{
            const videoRef = await ref(storage, dVideo.ref);
            await deleteObject(videoRef)
            const docRef = doc(db, `products/${products.id}/videos`, dVideo.id)
            await deleteDoc(docRef);

          }catch(err) {
            console.log(err,":::::::")
          }
        })

      }

    } else{
      const response = await axios.post(process.env.REACT_APP_API+"/product",{
          attachments:input.attachments,
          description:input.description,
          fobPrice:input.fobPrice,
          images:input.images,
          name:input.name,
          categories:input.categories,
          discount:input.discount,
          active:input.active,
          price: input.price
        }
      )
      if(response?.data?.id){
        const id = response?.data?.id
        const clientsRef = collection(db, `products/${id}/videos`)
        if(newVideos.length>0){
          newVideos.forEach(async(nVideo)=>{
            const refe = await ref(storage,`/products/${id}/videos/${nVideo.file.name}`)
            const uploadVideo =await uploadBytes(refe, nVideo.file)
            const url = await getDownloadURL(uploadVideo.ref)
            const videoObj = {
              url,
              ref:uploadVideo.metadata.fullPath
            }
            await setDoc(doc(clientsRef), videoObj);
          })
        }
      }
    }
      history.push("/admin/products")
  }

  function handleDelete(){
    history.push("/admin/products")
    axios.delete(process.env.REACT_APP_API+"/product/"+products.id)
    setShowModal(false)
  }

  const [showModal, setShowModal] = useState(false);

  
  function handleDeleteAttachment(indice){
    if(settings){
      let aux = input.attachments.filter((e,i)=>i!==indice)
      setInput({...input,attachments:aux})
    }
  }
  
  function handleDeleteImage(indice){
    if(settings){
      let aux = input.images.filter((e,i)=>i!==indice)
      setInput({...input,images:aux})
    }
  }

  function handleDeleteCategory(e){
    if(settings){
      const filtered = input.categories.filter((category) =>category!==e.id)
      setInput({...input,categories:filtered})
    }
  }

  const [categorias,setCategorias] = useState([])

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API+"/category")
    .then(({data})=>setCategorias(data.elements))

  },[input.categories])

  const [categoriesFilter, setCategoriesFilter] = useState([])

  const [categoriesExist, setCategoriesExist] = useState([])

  useEffect(()=>{ 
    if(input.categories===undefined)return
    let exist =[]
    let filtro = categorias.filter((c)=>{
      let aux = true
      input.categories.forEach(element => {
        if(element === c.id){
          exist.push(c)
          return aux = false
        }
      });
      return aux
    })
    setCategoriesExist(exist)
    setCategoriesFilter(filtro)
  },[categorias, input.categories])


  function handleAddCategory(e){
    setInput({...input,categories:[...input.categories,e.id]})
  }


 
  const [videos, setVideos] = useState([])

  const [deleteVideos, setDeleteVideos] = useState([])

  function handleDeleteVideos(video){
    if(settings){
      const newArr = videos.filter(({id})=>{
        if( video.id === id){
          setDeleteVideos([...deleteVideos,video])
          return false
        }else{
          return true
        }
      })
      setVideos(newArr)
    }
  }

  const [newVideos, setNewVideos] = useState([])

  function handleDeleteNewVideos({file}){
    if(settings){
      const deleteName = file.name
      const newArr = newVideos.filter(({file}) => file.name !== deleteName)
      setNewVideos(newArr)

    }
  }

  async function handleDrop(evt){
    evt.preventDefault();
    let files = evt.dataTransfer?.files;
    if(!files) files = evt.target.files
    let images = [...input.images]
    let attachments = [...input.attachments]
    let vid = [...newVideos]
    for (let i = 0; i < files.length; i++) {
      const fileRead= await handleReader(files[i])
      if(files[i].type.includes('image'))images.push({file: fileRead,featured:false,key:"images"})
      else if (files[i].type.includes('video')) vid.push({url:fileRead, file:files[i]})
      else attachments.push({key:"attachments", file: fileRead, name:files[i].name})
    }
    setNewVideos(vid)
    setInput({...input,images,attachments})
  }
  const handleReader = async (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsDataURL(file);
    });
  }

  function handlePreventDefault(e) {
    e.preventDefault();
  }

  function handleImageChange(checked,indice){
    const images = input.images.map((e,i) => {
      if(i===indice)return {...e,featured:checked}
      else return {...e, featured:false}
    })
    setInput({...input,images})
  }

  useEffect(()=>{
    if(input.margin){
      const valor = (Number(input.margin)*Number(input.fobPrice))/100 + Number(input.fobPrice);
      handleChange({target:{name:"price", value:Number(valor.toFixed(2))}});
    }
  },[input.margin])
  
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-pink-900 text-xl font-bold">Product Detail {input.id}</h6>
            <div>
              <button
                className="bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSettings}
              >
                {settings?"Save":"Modify"}
              </button>
              <button
                className="bg-red-500 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={()=>setShowModal(true)}
              >
                <i class="far fa-trash-alt"></i>
              </button>

            </div>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Product Detail
            </h6>
            <div className="flex flex-wrap">
              
            <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    disabled={settings?false:true}
                    name="name"
                    onChange={handleChange}
                    value={input.name}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>



              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                   FOB Price
                  </label>
                  <input
                    disabled={settings?false:true}
                    type="text"
                    name="fobPrice"
                    value={input.fobPrice}
                    onChange={handleChange}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              

              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    % Margin
                  </label>
                  <input
                    name="margin"
                    disabled={settings?false:true}
                    onChange={handleChange}
                    value={input.margin}
                    type="number"
                    pattern="[0-9]*"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />                
                  </div>
              </div>

            

              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Price
                  </label>
                  <input
                    name="price"
                    disabled
                    value={input.price}
                    type="number"
                    pattern="[0-9]*"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              {/* <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Discount
                  </label>
                  <input
                    name="discount"
                    disabled={settings?false:true}
                    onChange={handleChange}
                    value={input.discount}
                    type="number"
                    pattern="[0-9]*"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div> */}
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Display description
                  </label>
                  <input
                    name="description"
                    disabled={settings?false:true}
                    onChange={handleChange}
                    value={input.description}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-3/12 px-4">
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        disabled={settings?false:true}
                        id="customCheckLogin"
                        name="active"
                        type="checkbox"
                        checked={input.active}
                        onChange={(e)=>{
                          handleChange({...e , target:{name:e.target.name,value:e.target.checked}})}}
                        className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-gray-700">
                        Active
                      </span>
                    </label>
                  </div>
              </div>

              <div className="w-full lg:w-12/12 px-4 py-2">
                <Dropdown disabled={settings} handleAddCategory={handleAddCategory} data={categoriesFilter}/>
                <div className="w-full py-2">
                  {
                    categoriesExist.length>0 && categoriesExist.map((e)=>(
                    <span 
                    onClick={()=>handleDeleteCategory(e)} 
                    className={
                      settings
                      ?
                      "text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-blue-600 bg-blue-200 uppercase last:mr-0 mr-1 hover:delete-categorie outline-none"
                      :
                      "text-xs font-semibold inline-block py-1 px-2 uppercase rounded bg-grey-300 uppercase last:mr-0 mr-1 outline-none"
                    }
                    >
                      {e.name} <i class="far fa-times-circle"></i>
                    </span>
                    ))

                  }

                </div>


              </div>

            </div>
          </form>
        </div>


        <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          "bg-white" 
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              
              <label
                className="drop-border w-full text-center bg-transparent text-gray-600 font-bold uppercase text-3xl px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                for="imagesdrop"
                onDrop={handleDrop}
                onDragEnter={handlePreventDefault}
                onDragOver={handlePreventDefault}
              >
                {
                settings?
                <>Drop Files <i class="fab fa-dropbox"></i></>
                :
                'Click "Modify" to add'
                }
              </label>
                <input
                disabled={settings?false:true}
                style={{display:"none"}} 
                type="file" 
                id="imagesdrop" 
                multiple 
                onChange={handleDrop}
                />
            </div>
          </div>
        </div>
      </div>


      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
           "bg-white" 
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  "text-pink-900"
                }
              >
                Images
              </h3>
            </div>
           </div>
        </div>
        <div  className="flex w-full flex-wrap">
          {/* IMAGES table */}

          {
            input.images &&input.images.map((e,i)=>(
              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div 
                    onClick={()=>handleDeleteImage(i)}
                    className={
                        settings?
                        "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                        :
                        "bg-gray-300 text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full"
                        }
                        >
                      <i class={"far fa-trash-alt"}></i>
                    </div>
                    <img src={e.id ? process.env.REACT_APP_API+"/product/image/"+e.id : input.images[i].file}></img>
                    <div>
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          id="customCheckLogin"
                          disabled={settings?false:true}
                          type="checkbox"
                          className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                          name="featured"
                          checked={input.images[i].featured}
                          onChange={(e)=>{
                            handleImageChange(e.target.checked,i)
                          }}
                        />
                        <span className="ml-2 text-sm font-semibold text-gray-700">
                          Featured
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>



      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
           "bg-white" 
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  "text-pink-900"
                }
              >
                Videos
              </h3>
            </div>
           </div>
        </div>
        <div  className="flex w-full flex-wrap">
          {/* IMAGES table */}

          {
            videos.length>0 && videos.map((e,i)=>(
              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div 
                    onClick={()=>handleDeleteVideos(e)}
                    className={
                        settings?
                        "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                        :
                        "bg-gray-300 text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full"
                        }
                        >
                      <i class={"far fa-trash-alt"}></i>
                    </div>
                    <video controls src={e.url}></video>
                  </div>
                </div>
              </div>
            ))
          }

          {
            newVideos.length>0 && newVideos.map((e,i)=>(
              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div 
                    onClick={()=>handleDeleteNewVideos(e)}
                    className={
                        settings?
                        "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                        :
                        "bg-gray-300 text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full"
                        }
                        >
                      <i class={"far fa-trash-alt"}></i>
                    </div>
                    <video controls src={e.url}></video>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>


      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
           "bg-white" 
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  "text-pink-900"
                }
              >
                Attachments
              </h3>
            </div>
          </div>
        </div>
        <div  className="flex w-full flex-wrap">
          {/* ATTACHMENTS table */}

          {
            input.attachments &&input.attachments.map((e,indice)=>(
              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white text-center inline-flex items-center justify-center shadow-lg ">
                    <button
                      className={
                        settings?
                        "bg-red-500 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        :
                        "bg-gray-300 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      }
                        type="button"
                        onClick={()=>handleDeleteAttachment(indice)}
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>       
                    </div>
                    <a href={process.env.REACT_APP_API+"/product/attachment/"+e.id} target="_blank">
                      <h6 className="text-xl font-semibold">{e.name}</h6>
                    </a>
                  </div>
                </div>
              </div>
            ))
          }
          
        </div>
      </div>





      

        {showModal ? (
        <>
          <div
            style={{
              top: 0,
              right: 0,
              width: "100vw",
              height: "100vh",
              background: "#8080807d"}
            }
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setShowModal(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Are you sure?
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-gray-600 text-lg leading-relaxed">
                  This action cannot be returned
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleDelete}
                  >
                    Yes, Delete
                  </button>
                  <button
                    className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      </div>
      
    </>
  );
}


 

export const Dropdown = ({data,handleAddCategory,disabled}) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start"
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  
  useEffect(()=>{
    window.addEventListener("click", handleCloseCategories);
    return()=>{
      window.removeEventListener("click",handleCloseCategories);
    }
  },[])
  
  function handleCloseCategories(e){
    if(e.target.innerHTML !== "Categories"){
      closeDropdownPopover();
    }
  }
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-6/12 md:w-4/12">
          <div className="relative inline-flex align-middle w-full">
            <button
              disabled={!disabled}
              className={
                disabled
                ?
                "text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 active:bg-lightBlue-600 ease-linear transition-all duration-150 bg-green-400"
                :
                "text-black font-bold uppercase text-sm px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 active:bg-lightBlue-600 ease-linear transition-all duration-150 bg-grey-500"

              }
              type="button"
              ref={btnDropdownRef}
              onClick={() => {
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover();
              }}
            >
              Categories
            </button>
            <div
              ref={popoverDropdownRef}
              className={
                (dropdownPopoverShow ? "block " : "hidden ") +
                "bg-green-400 text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
              }
            >
             { 
              data.length>0 && data.map((d)=>(
                  <a
                    className="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-white"
                    onClick={e =>{
                       e.preventDefault()
                      handleAddCategory(d)
                      }}
                  >
                    {d.name}
                  </a>

              ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

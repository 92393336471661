import { fieldCreator } from "./fieldCreator";

export function listTableFields(projectType){
  const fields = {
    emo:fieldCreator([
                "id",
                "createdAt",
                "name",
                "email",
                "country",
                "identifier",
                "status"
    ]),
    pfp:fieldCreator([
      "id",
      "createdAt",
      "name",
      "email",
      "country",
      "identifier",
      "status"
    ]),
    roi:fieldCreator([
      "kwFrom",
      "kwTo",
      "percentage",
      "roi"
    ]),
    roles:fieldCreator([
      "id",
      "createdAt",
      "name",
      "active"
    ]),
    users:fieldCreator([
      "id",
      "fullName",
      "email",
      "active"
    ]),
    shippingoption:fieldCreator([
      "id",
      "name",
      "price",
      "active"
    ]),
    categories:fieldCreator([
      "id",
      "name"
    ]),
    products:fieldCreator([
      "id",
      "name",
      "price",
      "fobPrice",
      "active",
      "description"
    ]),
    productself:fieldCreator([
      "id",
      "name",
      "fobPrice",
      "description"
    ]),
    ordermanagement:fieldCreator([
      "id",
      "createdAt",
      "email",
      "status"
    ]),
}

    return fields[projectType]
}
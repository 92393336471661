import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss"
import { HeaderListTable } from "components/Headers/HeaderListTable";
import { getStorage, ref, uploadString, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { getFirestore, collection, doc, setDoc, getDoc,getDocs, runTransaction, } from "firebase/firestore"
import { useHistory } from "react-router";
import { CardListTableFirebase } from "components/Cards/CardListTableFirebase/CardListTableFirebase";
export default function ListTableClients({color,numbers,filterable, firebase}) {
    
    const db = getFirestore()
    const clientsRef = collection(db, "clients")
    const history = useHistory()

    const [data, setData] = useState([])

    async function handleGetInfo(){
        const querySnapshot = await getDocs(clientsRef);
        const arr = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            arr.push({...doc.data(), id:doc.id})
        });
        setData(arr)
    }
    const title="Clients"
    const fields = [{name:"name", label: "name"}]

    function handleHistoryPush(client){
        history.push({
            pathname:"/admin/clients/"+client.id,
            state:client
        })
    }

    useEffect(()=>{
        handleGetInfo()
    },[])

  return (
    <div id={styles.emo}>
        <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 px-4">
                <div
                    className={
                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                    (color === "light" ? "bg-white" : "bg-blue-900 text-white")
                    }
                >
                    <HeaderListTable 
                    title={title} 
                    color={color} 
                    button={{
                            title:"create", 
                            onClick:()=>history.push({
                                pathname:"/admin/clients/create",
                                state:{name:"", description_en:"", description_es:"", pdf:null, image:null}
                            })
                        }} 
                    />
                    <CardListTableFirebase fields={fields} color={color} data={data} handleHistoryPush={handleHistoryPush}/>
                </div>
            </div>
        </div>
    </div>
  );
}

ListTableClients.defaultProps = {
    color: "light",
};
  
ListTableClients.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
  };
  
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

// components

export default function CardShippingOptionCrud({shippingOption}) {

  const history = useHistory()

  const[input, setInput] = useState({})

  function handleChange(e){
    setInput({...input,[e.target.name]:e.target.value})
  }

  const [settings, setSettings] = useState(false)

  function handleSettings(){
    if(settings) handleSubmit()
    setSettings(!settings)
  }

  useEffect(()=>{
    if(shippingOption!==null){
      setSettings(false)
      setInput(
        {
          id:shippingOption.id,
          createdAt:shippingOption.createdAt.slice(0,10), 
          name: shippingOption.name,
          active: shippingOption.active,
          price: shippingOption.price
        }
      )
    }
    else if(shippingOption===null){
      setSettings(true)
    }
  },[shippingOption])

  function handleSubmit(){
    if(shippingOption){
      let aux = {...shippingOption,...input}
      axios.put(process.env.REACT_APP_API+"/shippingoption/"+shippingOption.id,aux)
    }
    else{
      axios.post(process.env.REACT_APP_API+"/shippingoption",{
         active:input.active,
         name:input.name,
         price:input.price
        }
     )
     history.push("/admin/shippingoption")
    }
  }

  function handleDelete(){
    history.push("/admin/shippingoption")
    axios.delete(process.env.REACT_APP_API+"/shippingoption/"+shippingOption.id)
    setShowModal(false)
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-pink-900 text-xl font-bold">Shipping Option Detail {input.id}</h6>
            <div>
              <button
                className="bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSettings}
              >
                {settings?"Save":"Modify"}
              </button>
              <button
                className="bg-red-500 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={()=>setShowModal(true)}
              >
                <i class="far fa-trash-alt"></i>
              </button>

            </div>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Shipping Option Detail
            </h6>
            <div className="flex flex-wrap">
              
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    disabled={settings?false:true}
                    name="name"
                    onChange={handleChange}
                    value={input.name}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Price
                  </label>
                  <input
                    type="text"
                    disabled={settings?false:true}
                    name="price"
                    onChange={handleChange}
                    value={input.price}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Active
                  </label>
                  <input
                    disabled={settings?false:true}
                    id="customCheckLogin"
                    name="active"
                    type="checkbox"
                    checked={input.active}
                    onChange={(e)=>{
                      handleChange({...e , target:{name:e.target.name,value:e.target.checked}})}}
                    className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        {showModal ? (
        <>
          <div
            style={{
              top: 0,
              right: 0,
              width: "100vw",
              height: "100vh",
              background: "#8080807d"}
            }
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setShowModal(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Are you sure?
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-gray-600 text-lg leading-relaxed">
                  This action cannot be returned
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleDelete}
                  >
                    Yes, Delete
                  </button>
                  <button
                    className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      </div>      
    </>
  );
}

import React from "react";

export function HeaderProjectCrud({title, id, handleSettings, settings, setShowModal}){
    return(
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-pink-900 text-xl font-bold">{title} Detail {id}</h6>
            <div>
              <button
                className="bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSettings}
              >
                {settings?"Save":"Modify"}
              </button>
              <button
                className="bg-red-500 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={()=>setShowModal(true)}
              >
                <i class="far fa-trash-alt"></i>
              </button>

            </div>
          </div>
        </div>
    )
}
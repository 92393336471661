import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";


// components

export default function CardOrderManagementCrud({order}) {

  const history = useHistory()

  const[input, setInput] = useState({})
  const[lines, setLines] = useState([])

  function handleChange(e){
    setInput({...input,[e.target.name]:e.target.value})
  }

  const [settings, setSettings] = useState(false)

  function handleSettings(){
    if(settings) handleSubmit()
    setSettings(!settings)
  }

  const[total, setTotal] = useState(0)

  useEffect(()=>{
    if(order!==null){
      setInput(
        {
          id:order.id,
          createdAt:order.createdAt.slice(0,10), 
          email:order.email, 
          status:order.status,
          phone:order.phone,
          shippingInformation: order.shippingInformation,
          invoicingInformation:order.invoicingInformation,
          shippingPrice:order.shippingPrice,
          suggestions: order.suggestions
        }
      )
      let totals=0
      order.lines.forEach(element => {
          totals+=parseInt(element.product.price)
      });
      setTotal(totals)
      setLines(order.lines)
    }
  },[order])

  function handleSubmit(){
    let aux = {...order, ...input}
    axios.put(process.env.REACT_APP_API+"/ordermanagement/"+order.id, aux)
  }

  function handleDelete(){
    history.push("/admin/order")
    axios.delete(process.env.REACT_APP_API+"/ordermanagement/"+order.id)
    setShowModal(false)
  }

  const [showModal, setShowModal] = useState(false);

  function handleShippingInformation(e){
    setInput({...input,shippingInformation:{...input.shippingInformation,[e.target.name]:e.target.value}})
  }

  function handleInvoicingInformation(e){
    setInput({...input,invoicingInformation:{...input.invoicingInformation,[e.target.name]:e.target.value}})
  }

  const[refresh, setRefresh] = useState(false)


  function handleLines(e,i){
    setRefresh(!refresh)
    let aux = lines;
    aux[i].finalPrice = e.target.value;
    setLines(aux);
  }


  let color ="light"

  async function handleQuotation(){
    setLoading("quotation")

    await axios.post(
      process.env.REACT_APP_API+"/ordermanagement/sendquote", 
        {
          orderId:input.id
        }
      )
    setLoading(false)
  }
  async function handlePreviewQuotation(){
    setLoading("preview")
    await axios.post(
      process.env.REACT_APP_API+"/ordermanagement/sendquote", 
        {
          orderId:input.id,
          preview:true
        }
    )
    setLoading(false)
  }

  const [loading,setLoading] = useState(false)
  

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-pink-900 text-xl font-bold">Order Detail {input.id}</h6>
            <div>
              <button
                className="bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSettings}
              >
                {settings?"Save":"Modify"}
              </button>
              {/* <button
                className="bg-red-500 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={()=>setShowModal(true)}
              >
                <i class="far fa-trash-alt"></i>
              </button> */}

            </div>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
             Order Info
            </h6>
            <div className="flex flex-wrap">
              
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Order Total
                  </label>
                  <input
                    type="text"
                    disabled={true}
                    value={total}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    name="email"
                    disabled={settings?false:true}
                    onChange={handleChange}
                    value={input.email}
                    type="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                   Project Date
                  </label>
                  <input
                    disabled={true}
                    type="text"
                    name="createdAt"
                    value={input.createdAt}
                    onChange={handleChange}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Status
                  </label>
                  <select
                    disabled={settings?false:true}
                    type="text"
                    name="status"
                    value={input.status}
                    onChange={handleChange}
                    className="px-3 py-3 placeholder-gray-200 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  >
                    <option value="Pending">Pending</option>
                    <option value="Checked">Checked</option>
                    <option value="Sent" >Sent</option>
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Customer Phone
                  </label>
                  <input
                    name="phone"
                    disabled={settings?false:true}
                    onChange={handleChange}
                    value={input.phone}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              
            </div>

            <div className="w-full lg:12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Suggestions
                  </label>
                  <textarea
                  type="text"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  rows="4"
                  name="suggestions"
                  disabled={true}
                  onChange={handleChange}
                  value={input.suggestions}
                  >
                  </textarea>

                </div>
              </div>
          </form>


          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
             Shipping Address
            </h6>
            <div className="flex flex-wrap">
              
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Country
                  </label>
                  <input
                    name="country"
                    disabled={settings?false:true}
                    onChange={handleShippingInformation}
                    value={input.shippingInformation && input.shippingInformation.country}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Province
                  </label>
                  <input
                    name="province"
                    disabled={settings?false:true}
                    onChange={handleShippingInformation}
                    value={input.shippingInformation && input.shippingInformation.province}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    City
                  </label>
                  <input
                    name="city"
                    disabled={settings?false:true}
                    onChange={handleShippingInformation}
                    value={input.shippingInformation && input.shippingInformation.city}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Postal Code
                  </label>
                  <input
                    name="postalCode"
                    disabled={settings?false:true}
                    onChange={handleShippingInformation}
                    value={input.shippingInformation && input.shippingInformation.postalCode}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    First Name
                  </label>
                  <input
                    name="firstName"
                    disabled={settings?false:true}
                    onChange={handleShippingInformation}
                    value={input.shippingInformation && input.shippingInformation.firstName}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Last Name
                  </label>
                  <input
                    name="lastName"
                    disabled={settings?false:true}
                    onChange={handleShippingInformation}
                    value={input.shippingInformation && input.shippingInformation.lastName}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Street Address
                  </label>
                  <input
                    name="streetAddress"
                    disabled={settings?false:true}
                    onChange={handleShippingInformation}
                    value={input.shippingInformation && input.shippingInformation.streetAddress}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Address Detail
                  </label>
                  <input
                    name="apt"
                    disabled={settings?false:true}
                    onChange={handleShippingInformation}
                    value={input.shippingInformation && input.shippingInformation.apt}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              
            </div>
          </form>



          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
             Invoicing Address
            </h6>
            <div className="flex flex-wrap">
              
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Country
                  </label>
                  <input
                    name="country"
                    disabled={settings?false:true}
                    onChange={handleInvoicingInformation}
                    value={input.invoicingInformation && input.invoicingInformation.country}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Province
                  </label>
                  <input
                    name="province"
                    disabled={settings?false:true}
                    onChange={handleInvoicingInformation}
                    value={input.invoicingInformation && input.invoicingInformation.province}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    City
                  </label>
                  <input
                    name="city"
                    disabled={settings?false:true}
                    onChange={handleInvoicingInformation}
                    value={input.invoicingInformation && input.invoicingInformation.city}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Postal Code
                  </label>
                  <input
                    name="postalCode"
                    disabled={settings?false:true}
                    onChange={handleInvoicingInformation}
                    value={input.invoicingInformation && input.invoicingInformation.postalCode}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    First Name
                  </label>
                  <input
                    name="firstName"
                    disabled={settings?false:true}
                    onChange={handleInvoicingInformation}
                    value={input.invoicingInformation && input.invoicingInformation.firstName}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Last Name
                  </label>
                  <input
                    name="lastName"
                    disabled={settings?false:true}
                    onChange={handleInvoicingInformation}
                    value={input.invoicingInformation && input.invoicingInformation.lastName}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Street Address
                  </label>
                  <input
                    name="streetAddress"
                    disabled={settings?false:true}
                    onChange={handleInvoicingInformation}
                    value={input.invoicingInformation && input.invoicingInformation.streetAddress}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Address Detail
                  </label>
                  <input
                    name="apt"
                    disabled={settings?false:true}
                    onChange={handleInvoicingInformation}
                    value={input.invoicingInformation && input.invoicingInformation.apt}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>


              
            </div>
          </form>



          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
             Shipping Price
            </h6>
            <div className="flex flex-wrap">
              
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    US$
                  </label>
                  <input
                    name="shippingPrice"
                    disabled={settings?false:true}
                    onChange={handleChange}
                    value={input.shippingPrice && input.shippingPrice }
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

            </div>
          </form>


        </div>
        {showModal ? (
        <>
          <div
            style={{
              top: 0,
              right: 0,
              width: "100vw",
              height: "100vh",
              background: "#8080807d"}
            }
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setShowModal(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Are you sure?
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-gray-600 text-lg leading-relaxed">
                  This action cannot be returned
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleDelete}
                  >
                    Yes, Delete
                  </button>
                  <button
                    className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      </div>
      
      


      <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
           "bg-white"
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-pink-900" : "text-white")
                }
              >
                Lines
              </h3>
            </div>
            <button
                className="bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSettings}
              >
                {settings?"Save":"Modify"}
              </button>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "w-20 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Product
                </th>
                <th
                  className={
                    "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Cost
                </th>
                <th
                  className={
                    "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Quantity
                </th>
                <th
                  className={
                    "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Final Price
                </th>
                
              </tr>
            </thead>
            <tbody>
              {
                lines.length > 0&& lines.map( (d,i)=>{          
                return (
                  <tr key={d.id} >

                  <td  className=" border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left flex items-center">
                      {d.product.name}
                  </td>

                  <td className=" border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap ">
                    {d.product.fobPrice}
                  </td>

                  <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap ">
                    {d.qty} 
                  </td>

                  <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap ">
                    <input
                    name="finalPrice"
                    disabled={settings?false:true}
                    onChange={(e)=>handleLines(e,i)}
                    value={d.finalPrice}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 rounded text-sm "
                  />
                  </td>

              </tr>
                )
              }
              )
              }
              

            </tbody>
          </table>
        </div>
      </div>
      </>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <button
          className="button-disabled bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          disabled={settings?true:loading?true:false}
          onClick={handlePreviewQuotation}
        >
          {
            !(loading==="preview" )
            ?
            "Send Preview Quotation"
            :
            <i class="fas fa-spinner animation-spinner text-base text-green-500"></i>
          }
        </button>
      </div>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <button
          className="button-disabled bg-red-400 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          disabled={settings?true:loading?true:false}
          onClick={handleQuotation}
        >
          {
            !(loading==="quotation" )
            ?
            "Send Quotation"
            :
            <i class="fas fa-spinner animation-spinner text-base text-green-500"></i>
          }
        </button>
      </div>
    </>
  );
}

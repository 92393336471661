import React, { useEffect, useState } from "react";

// components

import axios from "axios";
import { useParams } from "react-router";
import CardShippingOptionCrud from "components/Cards/CardShippingOptionCrud/CardShippingOptionCrud";


export default function ShippingOptionCrud() {
  const [shippingOption, setShippingOption] = useState(null)
  const {id} = useParams()

  useEffect(()=>{
    if(id!=="create")axios.get(process.env.REACT_APP_API+"/shippingoption/"+id)
    .then((d)=> setShippingOption(d.data))
    
  },[id])


  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardShippingOptionCrud shippingOption={shippingOption} />
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { listTableFields } from "helpers/listTablesFields";
import { useHistory } from "react-router";
import { titleTablesCreator } from "helpers/titleTablesCreator";
import { apiPaths } from "helpers/apiPaths";
import { buttonTableCreator } from "helpers/buttonTableCreator";

export function useListTable(){

    const [data,setData] = useState([])
    const [sort, setSort] = useState("id")
    const [sortDirection, setSortDirection] = useState(true)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState({start:0, end:10})
    const [rows, setRows] = useState(0)
    const [filter, setFilter] = useState("")
    const history = useHistory()
    const [totalRows, setTotalRows] = useState(0)
    const projectType = history.location.pathname.split("/").pop()

    const projectApiPath = apiPaths(projectType)
    const fields = listTableFields(projectType)
    const title = titleTablesCreator(projectType)
    const button = buttonTableCreator (projectType, handleHistoryPush)

    useEffect(()=>{
        axios.get(process.env.REACT_APP_API+`/${projectApiPath}?_end=${pagination.end}&_order=${sortDirection?"DESC":"ASC"}&_sort=${sort}&_start=${pagination.start}&_where=${filter}`)
        .then(({data:{elements, count}})=>{
            setData(elements)
            setRows(count)
        })
    },[sortDirection,sort,pagination,filter])
    
    
    useEffect(()=>{
        setPages(Math.ceil(rows/rowsPerPage))
    },[rows,rowsPerPage])
    

    function handleSort(e){
        if(e===sort) return setSortDirection(!sortDirection)
        setSort(e)
    }

    function handlePagination(d){
        if(d==="left"){
            if(page>1)setPage(page-1)
        }
        else if(d==="right"){
            if(page<pages)setPage(page+1)
        }
        else setPage(parseInt(d))
    }

    useEffect(()=>{
        setPagination({start:rowsPerPage*page-rowsPerPage, end:rowsPerPage*page})
    },[page, rowsPerPage])

    function handleFilter(e){
        e.preventDefault()
        if(projectType==='products'){
            if(e.target.abbr==="Check") setFilter(`{%22check%22:"false"}`)
            else setFilter(`{%22active%22:"${e.target.abbr==="Active"?true:false}"}`)
        }else{
            setFilter(`{%22status%22:"${e.target.abbr}"}`)
        }
    }

    function selectedFilter(){
        if(projectType==='products'){
            if(filter.slice(filter.indexOf('%22')+3,filter.lastIndexOf('%22')) === "check") return "Check"
            else if(filter.slice(filter.indexOf('"')+1,filter.lastIndexOf('"')) === "true") return "Active"
            else if(filter.slice(filter.indexOf('"')+1,filter.lastIndexOf('"')) === "false") return "Inactive"
        }else{
            return filter.slice(filter.indexOf('"')+1,filter.lastIndexOf('"'))
        }
    }
    const isProducts = projectType === 'products' ? true:false;

    function handleHistoryPush(id){
        if(!id)history.push(`/admin/${projectType}/create`)
        else history.push(`/admin/${projectType}/${id}`)
    }

    return{
        data,
        pages,
        page,
        filter,
        fields,
        handleFilter,
        handleSort,
        handlePagination,
        setRowsPerPage,
        handleHistoryPush,
        title,
        selectedFilter,
        isProducts,
        button
    }
}
import React from "react";
import PropTypes from "prop-types";
import { LinesTable } from "components/Tables/LinesTable";
import { HeaderLinesTable } from "components/Headers/HeaderLinesTable";
export default function CardLinesTable({ 
  fields,
  color,
  lines, 
  settings, 
  handleLineChange, 
  setShowModal,
  lineErrors
}) {

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-blue-900 text-white")
        }
      >
        <HeaderLinesTable 
          color={color} 
        />
        <LinesTable 
          fields={fields} 
          lines={lines} 
          setShowModal={setShowModal} 
          settings={settings}
          handleLineChange = {handleLineChange}
          color={color}
          lineErrors={lineErrors}
        />
      </div>
   </>
  );
}

CardLinesTable.defaultProps = {
  color: "light",
};

CardLinesTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

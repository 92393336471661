export function titleTablesCreator(projectType){

  const fields = {
    emo: 'Electric Motor Optimization',
    pfp: 'Electric Bill',
    roi: 'Roi Management',
    roles: 'Roles Management',
    users: 'Users Management',
    shippingoption: 'Shipping Options',
    categories: 'Categories Management',
    products: 'Products Management',
    productself: 'My Products',
    ordermanagement: 'Orders Management'
}

    return fields[projectType]
}

/*eslint-disable*/
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/img/admin_logo.png"

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar({permissions, notifications}) {
  const [collapseShow, setCollapseShow] = React.useState("hidden");

  const history =useHistory()

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            <img src={logo}/>
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    <img src={logo}/>
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Sales
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">

             {
             (permissions.includes("product") || permissions.length===0) && <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (history.location.pathname === "/admin/products"
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/products"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (history.location.pathname === "/admin/products"
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  <label className={"w-full"} >
                  Products
                  </label>
                  {
                  notifications?.products.check > 0
                  &&
                  <div className={"w-5 h-5 table text-white	 rounded-full	bg-red-600 text-center  inline-block"}>
                      <label>
                      {notifications.products.check}  
                      </label>
                  </div>
                  }
                </Link>
              </li>
              }

              {
                (permissions.includes("category") || permissions.length===0) &&
                <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/categories") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/categories"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/categories") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Categories
                </Link>
              </li>
              }

              {
                (permissions.includes("shippingoption") || permissions.length===0) &&
                <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/shippingoption") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/shippingoption"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/shippingoption") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Shipping Option
                </Link>
              </li>
              
              }

              {
                (permissions.includes("order") || permissions.length===0) &&
                <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/ordermanagement") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/ordermanagement"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/ordermanagement") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  <label className={" w-full "}>
                  Orders
                  </label>
                  {
                  notifications?.orders.pending > 0
                  &&
                  <div className={"w-5 h-5 table text-white	rounded-full	bg-red-600 text-center  inline-block"}>
                      <label >
                      {notifications?.orders.pending}  
                      </label>
                  </div>
                  }
                </Link>
              </li>
              }

              {
              /* (permissions.includes("orderself") || permissions.length===0) &&
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/orderself") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/orderself"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/orderself") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  My Orders
                </Link>
              </li> */
              }

              {
              (permissions.includes("productself") || permissions.length===0) &&  
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/productself") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/productself"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/productself") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  My Products
                </Link>
              </li>
              }

            </ul>

            {
            (permissions.includes("project") || permissions.length===0) &&
            <>
            <hr className="my-4 md:min-w-full" />
            
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Budgets
            </h6>
            

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">

            <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/emo") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/emo"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/emo") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  <label className={"w-full"} >
                  Electric Motor Optimization
                  </label>
                  {
                  notifications?.emo.pending > 0
                  &&
                  <div className={"w-5 h-5 table text-white	 rounded-full	bg-red-600 text-center  inline-block"}>
                      <label>
                      {notifications.emo.pending}  
                      </label>
                  </div>
                  }
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/pfp") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/pfp"
                >

                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/pfp") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  <label className={"w-full"} >
                  Electric Bill
                  </label>
                  {
                  notifications?.pfp.pending > 0
                  &&
                  <div className={"w-5 h-5 table text-white	 rounded-full	bg-red-600 text-center  inline-block"}>
                      <label>
                      {notifications.pfp.pending}  
                      </label>
                  </div>
                  }
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/roi") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/roi"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/roi") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  ROI Management
                </Link>
              </li>
              
            </ul>
            </>
  }

{
             permissions.length===0 &&
            <>
            <hr className="my-4 md:min-w-full" />
            
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Advanced
            </h6>
            

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/clients") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/clients"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/clients") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Clients
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/roles") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/roles"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/roles") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Roles
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/users") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/users"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/users") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Users
                </Link>
              </li>
              {
              (permissions.includes("contacts") || permissions.length===0) &&
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex items-center " +
                    (window.location.href.indexOf("/admin/contacts") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/contacts"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/contact") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Contacts
                </Link>
              </li>
              }
              
            </ul>

            <hr className="my-4 md:min-w-full" />
            </>
  }
          </div>
        </div>
      </nav>
    </>
  );
}

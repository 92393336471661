import React from "react";

export function ModalCrud({setShowModal, showModal, handleDelete}){
    let render  

    if(showModal.image){  
      render= <>
      <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
      <button
        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
        onClick={() => setShowModal(false)}
      >
        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
          ×
        </span>
      </button>
      </div>
      {/*body*/}
      <div className="relative p-6 flex-auto">
      <img style={{maxWidth: "95vw"}} src={showModal.image}/>
      </div>
      {/*footer*/}
      <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b"> 
      <button
        className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(false)}
      >
        Go back
      </button>
      </div>
      </>
  }else if(showModal.length>0){

    let errors = showModal.reduce((acc,objWord,i)=>{
      let word = []
      for (const key in objWord) {
        word.push(`Line${i+1}: In ${key} ${objWord[key]}`)
      }
      return [...acc,...word]
    },[])

    render = <>
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                {
                  errors.map((word)=>(
                    <p>{word}</p>
                  ))
                }
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b"> 
              <button
                className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Go back
              </button>
              </div>
      </>

  }else{
    render =
            <>
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                <h3 className="text-3xl font-semibold">
                  Are you sure?
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <div className="relative p-6 flex-auto">
                <p className="my-4 text-gray-600 text-lg leading-relaxed">
                This action cannot be returned
                </p>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleDelete}
                >
                  Yes, Delete
                </button>
                <button
                  className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Go back
                </button>
              </div>
          </>
    
  }

    return <>
          <div
            style={{
              top: 0,
              right: 0,
              width: "100vw",
              height: "100vh",
              background: "#8080807d"}
            }
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setShowModal(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
               {
                 render
               }
               
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
}
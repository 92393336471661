import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Settings from "views/admin/Settings.js";
import ProjectCrud from "views/admin/ProjectCrud/ProjectCrud";

import OrderSelfCrud from "views/admin/OrderSelfCrud/OrderSelfCrud";
import OrderManagementCrud from "views/admin/OrderManagementCrud/OrderManagementCrud";
import ProductSelfCrud from "views/admin/ProductSelfCrud/ProductSelfCrud";
import ROICrud from "views/admin/ROICrud/ROICrud";
import CategoriesCrud from "views/admin/CategoriesCrud/CategoriesCrud";
import ShippingOptionCrud from "views/admin/ShippingOptionCrud/ShippingOptionCrud";
import ProductsCrud from "views/admin/ProductsCrud/ProductsCrud";
import RolesCrud from "views/admin/RolesCrud/RolesCrud";
import UsersCrud from "views/admin/UsersCrud/UsersCrud"; 
import axios from "axios";

import Contacts from "views/admin/Contacts/Contacts";
import ListTable from "views/admin/ListTable/ListTable";
import { ClientsCrud } from "views/admin/ClientsCrud/ClientsCrud";
import ListTableClients from "views/admin/ListTableClients/ListTableClients";


export default function Admin({permissions}) {

  let exampleNotif={
                    pending:0,
                    sent:0,
                    checked:0
                  }
  const[notifications,setNotifications] = useState({
    products:{
      check:0,
      active:0,
      inactive:0
    },
    pfp:exampleNotif,
    emo:exampleNotif,
    orders: exampleNotif
})

  useEffect(()=>{
    handleSetNotifications()
  },[])

  async function getNotifications(){
    let {data} = await axios.get(process.env.REACT_APP_API+"/notifications")
    return data.data
  }

  async function handleSetNotifications(){
    setNotifications(await getNotifications())
  }

  return (
    <>
    
      <Sidebar permissions={permissions} notifications={notifications} />
      <div className="relative md:ml-64 bg-gray-200">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/settings" exact component={Settings} />
            {(permissions.includes("product") || permissions.length===0)&&
            <>
            <Route path="/admin/products" exact render={()=><ListTable filterable={true} numbers={notifications?.products} />} />
            <Route path="/admin/products/:id" exact render={()=><ProductsCrud setNotifications={ handleSetNotifications}  />} />
            </>
            }
            {(permissions.includes("category") || permissions.length===0)&&
              <>
                <Route path="/admin/categories" exact render={()=><ListTable />}  />
                <Route path="/admin/categories/:id" exact component={CategoriesCrud} />
              </>
            }
            {
              (permissions.includes("shippingoption") || permissions.length===0)&&
              <>
                <Route path="/admin/shippingoption" exact render={()=><ListTable />} />
                <Route path="/admin/shippingoption/:id" exact component={ShippingOptionCrud} />
              </>
            }
            {(permissions.includes("order") || permissions.length===0)&&
              <>
                <Route path="/admin/ordermanagement" exact render={()=><ListTable filterable={true} numbers={notifications?.orders}/>} />
                <Route path="/admin/ordermanagement/:id" exact render={()=><OrderManagementCrud setNotifications={ handleSetNotifications} /> } />
              </>
            }
            {
            /* 
            (permissions.includes("orderself") || permissions.length===0)&&
              <>
                <Route path="/admin/orderself" exact render={()=><ListTable />} />
                <Route path="/admin/orderself/:id" exact component={OrderSelfCrud} />
              </>
               */
            }
            {
            (permissions.includes("productself") || permissions.length===0)&&
              <>
                <Route path="/admin/productself" exact render={()=><ListTable />}/>
                <Route path="/admin/productself/:id" exact component={ProductSelfCrud} />
              </>
            }
            {(permissions.includes("project") || permissions.length===0)&&
              <>
                <Route path="/admin/emo" exact render={()=><ListTable filterable={true} numbers={notifications?.emo}/>} />
                <Route path="/admin/emo/:id" exact render={()=><ProjectCrud setNotifications={ handleSetNotifications} />} />
                <Route path="/admin/pfp" exact render={()=><ListTable filterable={true}  numbers={notifications?.pfp}/>} />
                <Route path="/admin/pfp/:id" exact render={()=><ProjectCrud setNotifications={ handleSetNotifications} />} />
                <Route path="/admin/roi" exact render={()=><ListTable />} />
                <Route path="/admin/roi/:id" exact component={ROICrud} />
              
              </>
            }
            {(permissions.includes("contacts") || permissions.length===0)&&
              <>
                <Route path="/admin/contacts" exact component={Contacts} /> 
              </>
            }
            {
              permissions.length===0 &&
              <>
                <Route path="/admin/roles" exact render={()=><ListTable />} />
                <Route path="/admin/roles/:id" exact component={RolesCrud} />
                <Route path="/admin/users" exact render={()=><ListTable />} />
                <Route path="/admin/users/:id" exact component={UsersCrud} />
                <Route path="/admin/clients" exact render={()=><ListTableClients firebase={true}/>} />
                <Route path="/admin/clients/:id" exact component={ClientsCrud} />
              </>
            }
        </div>
      </div>
    </>
  );
}

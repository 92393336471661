import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss"


export default function CardFilterByStatus({color, handleFilter, numbers,selected,products}){
    
    const first = products?"Check":"Pending";
    const second = products?"Active":"Checked";
    const third = products?"Inactive":"Sent";

    return(
        <div id={styles.emo}>
        
      <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 px-4">
 
                <div
                    className={
                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                    (color === "light" ? "bg-white" : "bg-blue-900 text-white")
                    }
                >
                    
                    <div className="rounded-t mb-0 px-4 py-3 border-0">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3
                                    className={
                                    "font-semibold text-lg " +
                                    (color === "light" ? "text-pink-900" : "text-white")
                                    }
                                >
                                    Filter By Status
                                </h3>
                            </div>
                        </div>
                    </div>


                    <div className="block w-full overflow-x-auto">
                    {/* Projects table */}

                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr>
                                    <th
                                    abbr={first}
                                    className={selected===first?
                                        "bg-pink-900 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-1 whitespace-no-wrap font-semibold text-center text-white " 
                                        :
                                        "px-6 align-middle bg-gray-100 border border-solid py-3 text-xs uppercase border-l-0 border-r-1 whitespace-no-wrap font-semibold text-center " 
                                        
                                    }
                                    name={first}
                                    onClick={handleFilter}
                                    >
                                    {first}
                                    </th>
                                    <th
                                     className={selected===second?
                                        "bg-pink-900 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-1 whitespace-no-wrap font-semibold text-center text-white " 
                                        :
                                        "px-6 align-middle bg-gray-100 border border-solid py-3 text-xs uppercase border-l-0 border-r-1 whitespace-no-wrap font-semibold text-center " 
                                        
                                    }
                                    abbr={second}
                                    onClick={handleFilter}
                                    >
                                    {second}
                                    </th>
                                    <th
                                      className={selected===third?
                                        "bg-pink-900 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center text-white " 
                                        :
                                        "px-6 align-middle bg-gray-100 border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center " 
                                        
                                    }
                                    abbr={third}
                                    onClick={handleFilter}
                                    >
                                    {third}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
        
                                <tr >
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center ">
                               {numbers[first.toLowerCase()]}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center ">
                                {numbers[second.toLowerCase()]}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center ">
                                {numbers[third.toLowerCase()]}
                                </td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                </div>

        </div>
      </div>
    </div>

    )
}

CardFilterByStatus.defaultProps = {
    color: "light",
};
  
CardFilterByStatus.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
  };
  
import { fieldCreator } from "helpers/fieldCreator"
import React from "react"


export default function CardCustomer({quotationInformation,handleCustomerChange,settings}){
  
  const fields = fieldCreator([
    "name",
    "taxId",
    "company",
    "province",
    "city",
    "typeOfBusiness",
    "commercialAddress",
    "phone"
  ])

    return(
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">

        <div className=" bg-white rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg text-pink-900" 
                }
              >
                Customer Info
              </h3>
            </div>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex mt-3 flex-wrap">
              {
                fields.map(({name,label,width='3',type='text'})=>(
                  <div className={`w-full lg:w-${width}/12 px-4`}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        {label}
                      </label>
                      <input
                        type={type}
                        disabled={settings?false:true}
                        name={name}
                        onChange={handleCustomerChange}
                        value={quotationInformation[name]}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                ))
              }
            </div>
          </form>
        </div>

      </div>
    )
}
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss"

// components
import PaginationDropDown from "components/Dropdowns/PaginationDropdown/PaginationDropdown.js";

import axios from "axios";
import { useHistory } from "react-router";

export default function Contacts({color}) {

    const history = useHistory()

    const [data,setData] = useState([])
    const [sort, setSort] = useState("id")
    const [sortDirection, setSortDirection] = useState(true)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState({start:0, end:10})
    const [rows, setRows] = useState([])
    const [refresh, setRefresh ] = useState(false)

    useEffect(()=>{
        axios.get(process.env.REACT_APP_API+`/contactmanagement?_end=${pagination.end}&_order=${sortDirection?"DESC":"ASC"}&_sort=${sort}&_start=${pagination.start}`)
        .then(data=>{
            setData(data.data)
        })
    },[sortDirection,sort,pagination,refresh])
    
    useEffect(()=>{
        axios.get(process.env.REACT_APP_API+`/contactmanagement`)
        .then(data=>{
            setRows(data.data)
        })
    },[])

    useEffect(()=>{
        setPages(Math.ceil(rows.length/rowsPerPage))
    },[rows, rowsPerPage])

    const handleEdit= async (id, active) => {
        await axios.put(`${process.env.REACT_APP_API}/contactmanagement/${id}`,{active: active, id:id})
        setRefresh(!refresh)
    }

    function handleSort(e){
        if(e===sort) return setSortDirection(!sortDirection)
        setSort(e)
    }

    function handlePagination(d){
        if(d==="left"){
            if(page>1)setPage(page-1)
        }
        else if(d==="right"){
            if(page<pages)setPage(page+1)
        }
        else setPage(parseInt(d))
    }

    useEffect(()=>{
        setPagination({start:rowsPerPage*page-rowsPerPage, end:rowsPerPage*page})
    },[page, rowsPerPage])
    
  return (
    <div id={styles.emo}>
      <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 px-4">
 
                <div
                    className={
                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                    (color === "light" ? "bg-white" : "bg-blue-900 text-white")
                    }
                >
                    
                    <div className="rounded-t mb-0 px-4 py-3 border-0">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3
                                    className={
                                    "font-semibold text-lg " +
                                    (color === "light" ? "text-pink-900" : "text-white")
                                    }
                                >
                                    Contacts
                                </h3>
                            </div>
                        </div>
                    </div>


                    <div className="block w-full overflow-x-auto">
                    {/* Projects table */}

                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr>
                                    <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                        (color === "light"
                                        ? "bg-gray-100 text-gray-600 border-gray-200"
                                        : "bg-blue-800 text-blue-300 border-blue-700")
                                        
                                    }
                                    onClick={()=>handleSort("id")}
                                    >
                                    Id
                                    </th>
                                    <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                        (color === "light"
                                        ? "bg-gray-100 text-gray-600 border-gray-200"
                                        : "bg-blue-800 text-blue-300 border-blue-700")
                                    }
                                    onClick={()=>handleSort("createdAt")}
                                    >
                                    Created At
                                    </th>
                                  
                                    <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                        (color === "light"
                                        ? "bg-gray-100 text-gray-600 border-gray-200"
                                        : "bg-blue-800 text-blue-300 border-blue-700")
                                    }
                                    onClick={()=>handleSort("name")}
                                    >
                                    Name
                                    </th>
                                    <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                        (color === "light"
                                        ? "bg-gray-100 text-gray-600 border-gray-200"
                                        : "bg-blue-800 text-blue-300 border-blue-700")
                                    }
                                    onClick={()=>handleSort("email")}
                                    >
                                    Email
                                    </th>
                                   
                                    <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                        (color === "light"
                                        ? "bg-gray-100 text-gray-600 border-gray-200"
                                        : "bg-blue-800 text-blue-300 border-blue-700")
                                    }
                                    onClick={()=>handleSort("phone")}
                                    >
                                    Phone
                                    </th>

                                    <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                        (color === "light"
                                        ? "bg-gray-100 text-gray-600 border-gray-200"
                                        : "bg-blue-800 text-blue-300 border-blue-700")
                                    }
                                    onClick={()=>handleSort("country")}
                                    >
                                    Country
                                    </th>
                                    <th
                                    className={
                                        " px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                        (color === "light"
                                        ? "bg-gray-100 text-gray-600 border-gray-200"
                                        : "bg-blue-800 text-blue-300 border-blue-700")
                                    }
                                    onClick={()=>handleSort("comments")}
                                    >
                                    Comments
                                    </th>

                                    <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                        (color === "light"
                                        ? "bg-gray-100 text-gray-600 border-gray-200"
                                        : "bg-blue-800 text-blue-300 border-blue-700")
                                    }
                                    onClick={()=>handleSort("status")}
                                    >
                                    Status
                                    </th>
                                    <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                        (color === "light"
                                        ? "bg-gray-100 text-gray-600 border-gray-200"
                                        : "bg-blue-800 text-blue-300 border-blue-700")
                                    }
                                    ></th>

                                </tr>
                            </thead>
                            <tbody>
                            {
                            data.length>0&&data.map((row, i)=>(
                                <tr key={row.id}>
                                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left flex items-center">
                                <span
                                    className={
                                    "ml-3 font-bold " +
                                    +(color === "light" ? "text-gray-700" : "text-white")
                                    }
                                >
                                    {row.id}
                                </span>
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                    {row.createdAt.slice(0,10)}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                    {row.name}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                    {row.email}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                    {row.phone}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                    {row.country}
                                </td>
                                <td className=" border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 ">
                                  <textarea disabled rows="3" cols="40">{`${row.comments}`}</textarea> 
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                <input
                                        id="customCheckLogin"
                                        name="active"
                                        type="checkbox"
                                        checked={row.active === true? false : true}
                                        // value={row.active}
                                        onChange={(e)=>{
                                            e.preventDefault()
                                          handleEdit(row.id, !e.target.checked)
                                        //   setState(row.active)
                                          }}
                                        className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                      />
                                {/* {row.active}  */}

                                </td>
                            </tr>
                            ))    
                            
                            }
                            </tbody>
                        </table>
                    </div>
                    <div id={styles.pagination} className="py-2">
                            <nav className="block">
                                <ul className="flex pl-0 rounded list-none flex-wrap">
                                <li name={"left"}>
                                    <button
                                    name={"left"}
                                    onClick={()=>handlePagination("left")}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-green-500">
                                    <i name={"left"} className="fas fa-chevron-left -ml-px"></i>
                                    </button>
                                </li>
                                {
                                    pages>0&&[...Array(pages)].map((e,i)=>(
                                            <li key={i}>
                                                <button
                                                className={page==i+1?
                                                    "first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 text-white bg-green-400"
                                                :
                                                "first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-green-500"
                                                }                                                    
                                                onClick={()=>handlePagination(i+1)}
                                                >
                                                {i+1}
                                                </button>
                                            </li>
                                    ))
                                }
                                <li 

                                >
                                    <button
                                    onClick={()=>handlePagination("right")}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-green-500">
                                    <i className="fas fa-chevron-right -mr-px"></i>
                                    </button>
                                </li>
                                </ul>
                            </nav>
                    </div>
                        <PaginationDropDown end={setRowsPerPage}/>
                </div>

        </div>
      </div>
    </div>
  );
}

Contacts.defaultProps = {
    color: "light",
};
  
Contacts.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
  };
  
import { wordCut } from "./wordCut"

export function fieldCreator(fields){
    return fields.map((field)=>{
        if(typeof field === 'object'){
          return {
            ...field,
            label: field.label? field.label : wordCut (field.name)
          }
        }else return{
          name:field,
          label: wordCut(field)
        }
      })
}
import { fieldCreator } from "./fieldCreator";
/* 
validation__
          debe devolver un objeto con prop validate true si cumple y false si no cumple
          y con infoError con descripcion del error
*/
export function projectFields(projectType){
  let fields;
  let linesFields;
  const disabled = true;
  if(projectType){
    fields = fieldCreator([
        {
          name:"status",
          type:"select",
          dropdownFields:[
            {
              name:"Sent",
              colorButton:'bg-green-400'
            },
            {
              name:"Pending",
              colorButton:'bg-orange-500'
            },
            {
              name:"Checked",
              colorButton:'bg-red-200'
            }
          ]
        },
        {
          name:"name",
          disabled
        },
        {
          name:"email",
          width:"6",
          disabled
        },
        {
          name:"country",
          disabled
        },
        {
          name:"createdAt",
          disabled
        },
        {
          name:"kWCost",
          disabled
        },
        {
          name:"totalMonthlySaving",
          disabled
        },
        {
          name:"roiAverage",
          disabled
        },
        {
          name:"projectCost",
          disabled
        },
        {
          name:"finalRoiAverage",
          disabled
        },
        {
          name:"totalProjectCost",
          disabled
        }
      ])
    linesFields = fieldCreator([
        {
        name:"motorName",
        tdStyle: "p-4 text-left flex items-center",
        disabled
        },
        "startMode",
        {
          name:"voltage",
          label:"volt",
          type:'number',
          disabled
        },
        {
          name:"amps",
          type:'number',
          disabled
        },
        {
          name:"kw",
          type:'number',
          disabled
        },
        {
          name:"hp",
          type:'number',
          disabled
        },
        {
          name:"powerFactor",
          label:"factor",
          type:'number',
          disabled
        },
        {
          name:"hoursPerDay",
          label:"h/D",
          type:'number',
          disabled
        },
        {
          name:"daysPerMonth",
          label:"d/M",
          type:'number',
          disabled
        },
        {
          name:"realRoi",
          type:'number',
          disabled
        },
        {
          name:"variableRoi",
          label:"var Roi",
          type:'number',
          disabled
        },
        {
          name:"realLineCost",
          type:'number',
          disabled
        },
        {
          name:"lineCost",
          type:'number',
          validation:(value, line)=>{
            return {
              validate:value >= line.minimumLineCost,
              errorInfo: `Value must be greater than ${line.minimumLineCost}`
            }
          }
        },
        "img",
        {
          name:"active",
          type:"checkbox"
        }
        ])

  }else{
    fields = fieldCreator ([
      {
        name:"name",
        width:"6",
      },
      {
        name:"email",
        width:"6",
      },
      "createdAt",
      {
        name:"status",
        type:"select",
        dropdownFields:[
          {
            name:"Sent",
            colorButton:'bg-green-400'
          },
          {
            name:"Pending",
            colorButton:'bg-orange-500'
          },
          {
            name:"Checked",
            colorButton:'bg-red-200'
          }
        ]
      },
      "country"
    ])

    linesFields = fieldCreator([
      "month",
      "supplyType",
      "penaltyAmount",
      "img"
    ])
  }
    return {fields, linesFields}
}
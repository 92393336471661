import React, { useEffect } from "react";
import { createPopper } from "@popperjs/core";
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";


const UserDropdown = (props) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-gray-600 block"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
          <i style={{color:"black", fontSize:"2rem"}} className="fas fa-user-tie"></i>
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
            <a
          className="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          style={{color:"transparent"}}
          onClick={(e)=>{
            e.preventDefault()
          }}
       >
          <i style={{color:"transparent"}} className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i>{" "}
          LogOut
        </a>
        <a
          style={{cursor:"pointer"}}
          className="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          onClick={(e)=>{
            e.preventDefault()
            const auth = getAuth();
            signOut(auth)
          }}
       >
          <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i>{" "}
          LogOut
        </a>
        <Link
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent  " +
            (window.location.href.indexOf("/admin/settings") !== -1
              ? "text-green-500 hover:text-blue-600"
              : "text-gray-800 hover:text-gray-600")
          }
          to="/admin/settings"
          onClick={()=>closeDropdownPopover()}
        >
          <i
            className={
              "fas fa-tools mr-2 text-sm " +
              (window.location.href.indexOf("/admin/settings") !== -1
                ? "opacity-75"
                : "text-gray-400")
            }
          ></i>{" "}
          Settings
        </Link>
      </div>
    </>
  );
};

export default UserDropdown;

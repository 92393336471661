import React from "react";
import { StatusDropdown } from "components/Dropdowns/StatusDropdown";

export function CardProjectInfo({fields, input, handleChange, settings}){
    return(
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Project Info
            </h6>
            <div className="flex flex-wrap">

            {
                fields.map(({name, label, type = 'text', width = '3', disabled=false, dropdownFields})=>(
                    <div className={`w-full lg:w-${width}/12 px-4`}>
                        <div className="relative w-full mb-3">
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            {label}
                        </label>
                        {
                            type === 'select'
                            ?
                            <StatusDropdown 
                                selected={input[name]} 
                                dropdownFields={dropdownFields}
                                name={name}
                                handleChange={handleChange}
                                disabled={disabled?disabled:!settings}
                            />
                            :
                            <input
                                type={type}
                                disabled={disabled?disabled:!settings}
                                name={name}
                                onChange={handleChange}
                                value={input[name]}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            />
                        }
                        </div>
                    </div>
                ))
            }
            </div>
          </form>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { createPopper } from '@popperjs/core';
import styles from "./index.module.scss"

const dropdownStyle = { minWidth: "12rem" }

const Dropdown = ({end}) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "top"
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };


  const[rowsPerPage, setRowsPerPage]=useState(10)
  
  useEffect(()=>{
    end(rowsPerPage)
  },[rowsPerPage])

  function handleChange(e){
    setRowsPerPage(e.target.name)
    setDropdownPopoverShow(false)
  }

  return (
    <>
      <div id={styles.rowsPerPage} className="flex flex-wrap">
        <div >
          <div className="relative inline-flex align-middle w-full">
            <button
              className="text-white font-bold uppercase text-sm px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 bg-green-400 active:bg-green-500 ease-linear transition-all duration-150"
              type="button"
              ref={btnDropdownRef}
              onClick={() => {
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover();
              }}
            >
              {rowsPerPage}
            </button>
            <div
              ref={popoverDropdownRef}
              className={
                (dropdownPopoverShow ? "block " : "hidden ") +
                "bg-green-400 text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 "
              }
            >
              <button
                className="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-white"
                name="10"
                onClick={handleChange}
              >
                10
              </button>
              <button
                className="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-white"
                name="20"
                onClick={handleChange}
              >
                20
              </button>
              <button
                className="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-white"
                name="40"
                onClick={handleChange}
              >
                40
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdown;
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import cl from "../assets/img/admin_ri.png"
import cr from "../assets/img/admin_rd.png"

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

export default function Auth(props) {
  return (
    <>
      <main>
        <section className="flex relative w-full h-full py-10 min-h-screen">
          <img 
          style={{
            width:"30vw"
          }}
          className="absolute top-0 w-2/6 z-50" 
          src={cl}></img>
          <img 
          style={{
            width:"30vw"
          }}
          className="absolute bottom-0 right-0 w-2/6 z-50" 
          src={cr}></img>
          <div
            className="absolute top-0 h-screen w-screen  bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/admin_background.png") + ")",
                backgroundSize:"cover",
                filter:"opacity(0.5)",
                width:"100vw"
            }}
          ></div>
            <Switch>
              <Route path="/auth/login" exact component={()=><Login setAuth={props.setAuth}/>} />
              {/* <Route path="/auth/register" exact component={Register} /> */}
              <Redirect from="/auth" to="/auth/login" />
            </Switch>
        </section>
      </main>
    </>
  );
}

import React, { useEffect, useState } from "react"
import axios from "axios"
import { useHistory, useParams } from "react-router"
import { useLocalStorage } from "react-use"
import { projectFields } from "helpers/projectFields"
import { projectLineValidator } from "helpers/projectLineValidator"

export async function getImage (id, user,projectApiPath){
  let exist=await axios.get(`${process.env.REACT_APP_API}/electric-motor-optimization/line/image/${id}`)
  if(exist.data.success===false) return false
  return fetch(`${process.env.REACT_APP_API}/electric-motor-optimization/line/image/${id}`,{
      headers:{
        'Authorization': user
      }
    } 
  )
}

export const getData = async (lines, user,projectApiPath) => {
  return Promise.all(
    lines.map( async (m)=>{
        let r = await getImage(m.id, user,projectApiPath)
        if(r===false)return{...m,image:false}
        let buffer = await r.arrayBuffer() 
        const blob = new Blob( [ buffer ] );
        const url = URL.createObjectURL( blob );
        return {...m,image:url}

      }
    )
  )
}


export function useProjectCrud({setNotifications}){
  const history = useHistory()

  const[user]=useLocalStorage("user",false)

  const projectType = history.location.pathname.includes("emo")
  const projectApiPath = projectType ? "electric-motor-optimization" : "power-factor-penalties"
  const projectAdminPath = projectType ? "emo" : "pfp"
  
  const [input, setInput] = useState({})
  const [showModal, setShowModal] = useState(false);

  function handleChange(e){
    setInput({...input,[e.target.name]:e.target.value})
  }

  function handleLineChange(id,e){
    let lines = input.lines.map((line)=>{
      if(line.id !== id) return line
      else{
        if(e.target.type === 'checkbox')return {...line, [e.target.name] : e.target.checked}
        return {...line, [e.target.name] : e.target.value}
      } 
    })
    setInput({...input, lines})
  }
    
  const [settings, setSettings] = useState(false)
  
  async function handleSettings(){
    if(settings) await handleSubmit()
    setSettings(!settings)
  }

  const { id } = useParams()

  async function getId(){
    const {data} = await axios.get(`${process.env.REACT_APP_API}/${projectApiPath}/${id}`)
    if(data){
      if(data.lines.length>0){
        getData(data.lines, user, projectApiPath)
        .then(d=>setInput({...data, lines:d}))
      }else{
        setInput({...data})
      }
      setSeeDate(data.quotationDates.slice(0,1))
    }

  }

  useEffect (()=>{
    getId()
    return ()=>setNotifications()
  },[])

  const [lineErrors,setLineErrors]=useState([])
  
  async function handleSubmit(){
    
    let { aux, errors, errorCount } =  projectLineValidator(input,linesFields)

    if(errorCount>0){
      setInput(aux)
      setLineErrors(errors)
      setShowModal(errors)
    }else{
      aux.lines = aux.lines.map((line)=>{
        delete line.error
        delete line.image
        return line
      })
      delete aux.createdAt
      await axios.put(`${process.env.REACT_APP_API}/${projectApiPath}/${input.id}`,aux)
      await getId()
    }
  }

  async function handleDelete(){
    history.push(`/admin/${projectAdminPath}`)
    await axios.delete(`${process.env.REACT_APP_API}/${projectApiPath}/${input.id}`)
    setShowModal(false)
  }


  async function handleQuotation(){
    if(settings) await handleSettings()
    await axios.post(process.env.REACT_APP_API+"/project/sendquotation", {
        id
      }
    )
    setSeeDate([{createdAt:"Just Now"},...seeDate])
  }

  async function handlePreviewQuotation(){
    if(settings) await handleSettings()
    window.open(process.env.REACT_APP_API+"/project/attachment/"+id)
  }

  const [seeDate, setSeeDate] = useState([])

  function handleSeeDate(){
    if(seeDate.length===1){
      setSeeDate(input.quotationDates)
    }else{
      setSeeDate(input.quotationDates.slice(0,1))
    }
  }

  function handleCustomerChange(e){
    setInput(
      {...input,
        quotationInformation:
        {
          ...input.quotationInformation,
          [e.target.name]:e.target.value}
        }
    )
  }
  
  const {fields, linesFields}= projectFields(projectType)


  return {
    handleChange,
    handleLineChange,
    handleSubmit,
    handleDelete,
    handleSettings,
    handlePreviewQuotation,
    handleQuotation,
    handleSeeDate,
    setShowModal,
    handleCustomerChange,
    showModal,
    input,
    seeDate,
    settings,
    fields,
    linesFields,
    projectType,
    lineErrors
  }
}

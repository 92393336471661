import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import logo from "../../assets/img/admin_logo.png"

export default function Login(props) {

  const[input, setInput] =  useState({email:"", password:""})
  const[loading,setLoading] = useState(false)

  async function handleSubmitLogin(){
    try {
      setLoading(true)
      const auth = await getAuth();
      await signInWithEmailAndPassword(auth, input.email, input.password).catch((err)=>console.log(err))
      setLoading(false)
    } catch (error) {
        console.log("ERROR",error)
        setLoading(false)
        throw error;
    }
  }


  function handleChange(e){
    setInput({...input,[e.target.name]:e.target.value})
  }

  return (
    <>
      <div className=" self-center justify-self-center container mx-auto px-4 h-full">
        <div className="flex flex-col md:flex-row content-center items-center h-full">
          <img className="relative " style={{width:"24rem"}} src={logo}></img>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
              {/* <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-gray-600 text-sm font-bold">
                    Sign in with
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/github.svg")}
                    />
                    Github
                  </button>
                  <button
                    className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg")}
                    />
                    Google
                  </button>
                </div>
                <hr className="mt-6 border-b-1 border-gray-400" />
              </div> */}
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-gray-500 text-center mb-3 font-bold">
                  <h2>Welcome</h2>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      disabled={loading}
                      type="email"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      name="email"
                      value={input.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      disabled={loading}
                      type="password"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      name="password"
                      value={input.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    {/* <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-gray-700">
                        Remember me
                      </span>
                    </label> */}
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="login-button bg-green-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleSubmitLogin}
                      disabled={loading}
                    >
                      {
                        loading
                        ?
                        <i class="fas fa-spinner animation-spinner text-base text-green-500"></i>
                        :
                        "Sign In"
                      }
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                {/*
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-gray-600"
                >
                  <small>Forgot password?</small>
                </a> 
                */}
              </div>
              {/* <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-gray-600">
                  <small>Create new account</small>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";

// components

import axios from "axios";
import { useParams } from "react-router";
import CardUsersCrud from "components/Cards/CardUsersCrud/CardUsersCrud";


export default function UsersCrud() {
  const [users, setUsers] = useState(null)
  const {id} = useParams()

  useEffect(()=>{
    if(id!=="create")axios.get(process.env.REACT_APP_API+"/users/"+id)
    .then((d)=> setUsers(d.data))
    
  },[id])


  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardUsersCrud users={users} />
        </div>
      </div>
    </>
  );
}

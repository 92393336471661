import React, { useEffect, useState } from "react";

// components

import axios from "axios";
import { useParams } from "react-router";
import CardProductsCrud from "components/Cards/CardProductsCrud/CardProductsCrud";


export default function ProductsCrud({setNotifications}) {
  const [products, setProducts] = useState(null)
  const {id} = useParams()

  async function getData(){
    try{
      let {data} =await axios.get(process.env.REACT_APP_API+"/product/"+id)
      setProducts(data)
      if(!data.check) await axios.put(process.env.REACT_APP_API+"/product/"+id,{...data,check:true})
    }catch(e){
      console.log(e);
    }
  }

  useEffect(()=>{
    getData()
  },[id])

  useEffect(()=>{
    return ()=>{
      setNotifications()
    }
  },[])
  

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardProductsCrud products={products} />
        </div>
      </div>
    </>
  );
}

import React from "react";

export function LinesTable({
  fields, 
  lines, 
  setShowModal, 
  color, 
  settings, 
  handleLineChange,
  lineErrors
}){
    return(
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {
                  fields.map(({label})=>(
                      <th
                        className={
                          "px-1 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-gray-100 text-gray-600 border-gray-200"
                            : "bg-blue-800 text-blue-300 border-blue-700")
                          }
                          >
                        {label}
                      </th>
                    )
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                lines.length > 0&& lines.map( (line,lineIndex)=>{          
                    return (
                      <tr 
                      key={line.id} 
                      className={!line.active&&'line-inactive'}
                      >
                        {
                          fields.map(({name,tdStyle="",type='text',disabled=false})=>(
                            <td className={`border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap ${tdStyle}`} >
                                {
                                  name !== 'img'
                                  ?
                                  <input 
                                  className={`w-full  ${lineErrors[lineIndex] && lineErrors[lineIndex][name] && 'background-line-error'}`} 
                                  name={name} 
                                  checked={line[name]}
                                  disabled={disabled?disabled:!settings} 
                                  onChange={(e)=>handleLineChange(line.id,e)} 
                                  value={line[name]} 
                                  type={type}
                                  />
                                  :
                                  <div className="flex justify-center">
                                    {
                                      line.image?
                                        <img
                                            onClick={()=>setShowModal({image: line.image})}
                                            src={line.image}
                                            alt="..."
                                            className="w-10 h-10 rounded-full border-2 border-gray-100 shadow -ml-4"
                                        ></img> 
                                        :
                                        <i class="fas fa-exclamation"></i>
                                      }
                                  </div>
                                }
                            
                            </td>
                          ))
                        }
                      </tr>
                    )
                  } 
                )
              }
            </tbody>
          </table>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import { useLocalStorage } from "react-use";
import axios from "axios";

import { getAuth, onAuthStateChanged } from "firebase/auth";



export default function App(){

    const [show, setShow] = useState(null)
    const[user,setUser,setRemoveUser]=useLocalStorage("user",false)


    useEffect(()=>{
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
        // Check for user status
            try {
                if(user){
                    const token = `${auth.currentUser?.uid}:${await auth.currentUser?.getIdToken()}`;
                    axios.defaults.headers.common['Authorization'] = `${token}`
                    setUser(token)
                    let {data} = await axios.get(process.env.REACT_APP_API+"/auth/me")
                    let permissions = data.data.permissionsResumee.map((p)=>{
                    let aux = p.split(".")
                    let per
                    if(aux.length>2)per=aux[0]+aux[1]
                    else per = aux[0]
                    return per
                    })
                    let notifications = await axios.get(process.env.REACT_APP_API+"/notifications")   
                    setShow(
                        <BrowserRouter>

                            <Switch>
                                <Route path="/admin" render={()=><Admin permissions={permissions} user={user} notification={notifications.data.data} />} />
                                <Redirect from="*" to="/admin/dashboard" />
                            </Switch>

                        </BrowserRouter>
                        
                    )
                }
                else{
                    setShow(
                        
                        <BrowserRouter>
                        <Switch>
                            <Route path="/auth"render={()=><Auth  />} />
                            <Redirect from="*" to="/auth" />
                        </Switch>
                        </BrowserRouter>
                    )
                }
            } catch (error) {
                console.log(error,"ERROR")
            }
        });
    },[])



    return(show===null?
        <h1>LOADING</h1>
        :
        show
        )

}


import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss"


export default function CardDateQuotes({ color, quotationDates, handleSeeDate }) {


  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-blue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-pink-900" : "text-white")
                }
              >
                Dates
              </h3>
            </div>
            {
            quotationDates.length>0&&
            <div>
              <button
                className="bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSeeDate}
              >
                {quotationDates.length===1?"See All":"See last"}
              </button>
            </div>
            }

          </div>
        </div>
        <div  id={styles.tableContainer} className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>

                <th
                  className={
                    "px-1 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Sended
                </th>
                
              </tr>
            </thead>
            <tbody>
              {
                quotationDates.length > 0&& quotationDates.map( (d,i)=>{          
                
                return (
                    <tr key={d.id} >

                        <td  className=" border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left items-center">
                            {d.createdAt.replace("T"," ").slice(0,19)} 
                        </td>

                    </tr>
               
                )
              }
              )
              }

            </tbody>
          </table>
        </div>
      </div>
    
   </>
  );
}

CardDateQuotes.defaultProps = {
  color: "light",
};

CardDateQuotes.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};


export function wordCut(phrase){
    let index = [];
    let newWord = [];
    let last = ""
    for (let i = 0; i < phrase.length; i++) {
        if(phrase[i] === phrase[i].toUpperCase()) index.unshift(i)
    }

    if(index.length > 0){

        last = index.reduce((acc,curr)=>{
            newWord.unshift(acc.slice(curr))
            return acc.slice(0,curr)
        },phrase)
        
        return `${last} ${newWord.join(" ")}`.toLowerCase()
    }else{
        return phrase
    }

}
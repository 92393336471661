import React, { useEffect, useState } from "react";

// components

import CardSettings from "components/Cards/CardSettings.js";
import axios from "axios";

export default function Settings() {
  const [user, setUser] = useState(null)

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API+"/auth/profile/user")
    .then((d)=> setUser(d.data))
  },[])

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardSettings  user={user} />
        </div>

      </div>
    </>
  );
}

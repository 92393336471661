import React,{useEffect, useState} from "react";
import { createPopper } from '@popperjs/core';

export const StatusDropdown = ({name, handleChange, selected, dropdownFields, disabled=false}) => {
    // dropdown props
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);

    const [colorButton, setColorButton] = useState('')

    useEffect(()=>{
      dropdownFields.forEach(element => {
          if(element.name === selected){
              setColorButton(element.colorButton)
              return
          } 
      })
    },[selected])
    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();
    const openDropdownPopover = () => {
      createPopper (btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start"
      });
      setDropdownPopoverShow(true);
    };
    const closeDropdownPopover = () => {
      setDropdownPopoverShow(false);
    };
    function handleDropdownChange(select){
        let event={
            target:
            {
                name:name,
                value:select
            }
        }
        handleChange(event)
        handleShow()
    }
    function handleShow(){
        if(!disabled){
            dropdownPopoverShow
            ? closeDropdownPopover()
            : openDropdownPopover();
        }
    }

    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full sm:w-6/12 md:w-4/12">
            <div className="relative inline-flex align-middle w-full">
              <button
                className={
                    `
                    text-white
                    font-bold
                    uppercase
                    text-sm
                    px-6
                    py-3 
                    rounded
                    shadow
                    hover:shadow-lg
                    outline-none 
                    focus:outline-none 
                    mr-1 mb-1 
                    active:bg-lightBlue-600 
                    ease-linear 
                    transition-all 
                    duration-150 

                    ${colorButton}
                    ${
                        disabled && 'project-info-inactive'
                    }
                    `
                  //</div></div>"text-black font-bold uppercase text-sm px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 active:bg-lightBlue-600 ease-linear transition-all duration-150 bg-grey-500"
                }
                type="button"
                ref={btnDropdownRef}
                onClick={ handleShow}
              >
                {selected}
              </button>
              <div
                ref={popoverDropdownRef}
                className={
                  (dropdownPopoverShow ? "block " : "hidden ") +
                  "bg-white text-base  z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
                }
              >
               { 
                dropdownFields.length>0 && dropdownFields.map(({name})=>(
                    <a
                      className="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-black"
                      onClick={()=>handleDropdownChange(name)}
                    >
                      {name}
                    </a>
  
                ))
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
import React, { useState } from 'react'
import { getFirestore, collection, doc, setDoc, getDoc,getDocs, runTransaction,deleteDoc } from "firebase/firestore"
import { getStorage, ref, uploadString, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

export function ClientsCrud({history, location:{state:{id=null,name="", description_en="", description_es="", pdf=null, image=null}}}) {
  
  const db = getFirestore()
  const clientsRef = id ? doc(db, "clients", id) : collection(db, "clients")
  
  const [showModal, setShowModal] = useState(false)

  const imagePoint= image
  const pdfPoint = pdf

  const [settings, setSettings] = useState((name===""))
  const [input, setInput ] = useState({
    name,
    description_en,
    description_es,
    pdf,
    image,
  })

  function handleChangeInput(e){
    setInput({...input,[e.target.name]:e.target.value})
  }

  function handleSettings(){
    if(settings) handleSubmit()
    setSettings(!settings)
  }

  async function handleGetUploadFiles(){
    try{
      const storage = await getStorage()
      const files = { image:null, pdf:null }
      if(newFiles.image.file){
        const refe = await ref(storage,`/clients/${input.name.toLowerCase()}/${newFiles.image.file.name}`)
        const uploadImage =await uploadBytes(refe, newFiles.image.file)
        const url = await getDownloadURL(uploadImage.ref)
        const image = {
          url,
          ref:uploadImage.metadata.fullPath
        }
        files.image= image
      }
      if(newFiles.pdf.file){
        const refe = await ref(storage,`/clients/${input.name.toLowerCase()}/${newFiles.pdf.file.name}`)
        const uploadPdf =await uploadBytes(refe, newFiles.pdf.file)
        const url = await getDownloadURL(uploadPdf.ref)
        const pdf = {
          url,
          ref:uploadPdf.metadata.fullPath
        }
        files.pdf= pdf
      }

      return files

    }catch(err) {
      console.log(err,"error")
    }
  }

  async function handleDeleteFiles(){
    try{
      const storage = await getStorage()
      if(newFiles.image.file && imagePoint?.ref){
        const imageRef = await ref(storage, imagePoint.ref);
        await deleteObject(imageRef)
      }
      if(newFiles.pdf.file && pdfPoint?.ref){
        const pdfRef = await ref(storage, pdfPoint.ref);
        await deleteObject(pdfRef)
      }
    }catch(err){
      console.log(err);
    }
  }

  async function handleSubmit(){
    if(id){
      try{
        const modifiedInput = input
        const{image,pdf} = await handleGetUploadFiles()
        if(image) modifiedInput.image = image
        if(pdf) modifiedInput.pdf = pdf
        await runTransaction(db, async (transaction) => {
          const sfDoc = await transaction.get(clientsRef);
          if (!sfDoc.exists()) throw "Document does not exist!";
          transaction.update(clientsRef, modifiedInput);        
        });
        await handleDeleteFiles()
      }catch(err) {
        console.log(err,"error")
      }
      
    }
    else{
      try{
        const files = {}
        if(!newFiles.image.file)throw new Error("Debes llenar todos los campos y cargar una imagen")
        const{image,pdf} = await handleGetUploadFiles()
        if(image) files.image = image
        if(pdf) files.pdf = pdf
        await setDoc(doc(clientsRef), {...input,...files});
      }catch(err) {
        setShowModal("Error")
        return
      }
    }
    history.goBack();
  }

  async function handleDelete(){
    await deleteDoc(clientsRef);
    history.goBack()
  }

  function handleShowModalDelete(){
    setShowModal("Delete")
  }

  const [newFiles, setNewFiles] = useState({image:{base:null, file:null}, pdf:{base:null, file:null}})

  async function handleDrop(e){
    e.preventDefault()
    const file = e.target.files[0]
    if(file.type.includes("image")){
      const base = await handleReader(file)
      setNewFiles({...newFiles, image:{base,file}})
    }else{
      setNewFiles({...newFiles, pdf:{base:file.name, file}})
    }
  }

  const handleReader = async (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsDataURL(file);
    });
  }


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-pink-900 text-xl font-bold">Client Success</h6>
            <div>
              <button
                className="bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSettings}
              >
                {settings?"Save":id?"Modify":"Create"}
              </button>
              {id && <button
                className="bg-red-500 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleShowModalDelete}
              >
                <i class="far fa-trash-alt"></i>
              </button>}

            </div>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Product Detail
            </h6>
            <div className="flex flex-wrap">

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    disabled={settings ? false : true}
                    name="name"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-grey-200 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    value={input.name}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    English description
                  </label>
                  <textarea
                    type="text"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    rows="4"
                    name="description_en"
                    disabled={settings ? false : true}
                    value={input.description_en}
                    onChange={handleChangeInput}
                  >
                  </textarea>

                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Spanish description
                  </label>
                  <textarea
                    type="text"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    rows="4"
                    name="description_es"
                    disabled={settings ? false : true}
                    value={input.description_es}
                    onChange={handleChangeInput}
                  >
                  </textarea>

                </div>
              </div>
            </div>

          </form>
        </div>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
            "bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1 flex justify-between">
                <h3
                  className={
                    "font-semibold text-lg " +
                    "text-pink-900"
                  }
                >
                  Image
                </h3>
                <div>
                  <label
                    className="cursor-pointer bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    for="imageClient"
                    id={settings?"":"disabled"}
                  >
                    {imagePoint?"Change":"Add"}
                  </label>
                    <input
                    disabled={settings?false:true}
                    style={{display:"none"}} 
                    type="file" 
                    id="imageClient" 
                    onChange={handleDrop}

                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-wrap">
            {/* IMAGES table */}

            {
                    
                    <div className="w-full md:w-4/12 px-4 text-center">
                      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                        <div className="px-4 py-5 flex-auto">

                          <img src={
                            id
                            ?
                            newFiles.image.base
                            ?
                            newFiles.image.base
                            :
                            imagePoint
                            ?
                            imagePoint.url
                            :
                            null
                            :
                            newFiles.image.base}></img>
                        </div>
                      </div>
                    </div>
                  }

          </div>
        </div>


        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
            "bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1 flex justify-between">
                <h3
                  className={
                    "font-semibold text-lg " +
                    "text-pink-900"
                  }
                >
                  Attachments
                </h3>
                <div>
                  <label
                    className="cursor-pointer bg-green-400 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    for="pdfClient"
                    id={settings?"":"disabled"}
                  >
                    {pdfPoint?"Change":"Add"}
                  </label>
                  <input
                  disabled={settings?false:true}
                  style={{display:"none"}} 
                  type="file" 
                  id="pdfClient"
                  onChange={handleDrop}

                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-wrap">
            {/* ATTACHMENTS table */}

            {
                    
              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <a 
                    href={
                      id
                      ?
                      newFiles.pdf.base
                      ?
                      null
                      :
                      pdfPoint
                      ?
                      pdfPoint.url
                      :
                      null
                      :
                      null} 
                    target="_blank">
                      <h6 className="text-xl font-semibold">
                        {
                          id
                          ?
                          newFiles.pdf.base
                          ?
                          newFiles.pdf.base
                          :
                          pdfPoint && pdfPoint.ref.slice(pdfPoint.ref.lastIndexOf("/")+1)
                          :
                          newFiles.pdf.base
                        }
                      </h6>
                    </a>
                    
                  </div>
                </div>
              </div>
            }

          </div>
        </div>







        {showModal ? (
          <>
            <div
              style={{
                top: 0,
                right: 0,
                width: "100vw",
                height: "100vh",
                background: "#8080807d"
              }
              }
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {showModal==="Error"?"Error": "Are you sure"}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    >
                      <span 
                      onClick={()=>setShowModal(false)}
                      className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <p className="my-4 text-gray-600 text-lg leading-relaxed">
                    {showModal==="Error"?"You must fill in all the fields and upload an image ": "This action cannot be returned"}
                    </p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                    {
                    
                    showModal
                    && 
                    showModal !== "Error" 
                    && 
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleDelete}
                    >
                      Yes, Delete
                    </button>}
                    <button
                      className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={()=>setShowModal(false)}
                    >
                      Go back
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>

    </>

  )
}
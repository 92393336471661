import React, { useEffect, useState } from "react";

// components

import axios from "axios";
import { useParams } from "react-router";
import CardCategoriesCrud from "components/Cards/CardCategoriesCrud/CardCategoriesCrud";


export default function CategoriesCrud() {
  const [categories, setCategories] = useState(null)
  const {id} = useParams()

  useEffect(()=>{
    if(id!=="create")axios.get(process.env.REACT_APP_API+"/category/"+id)
    .then((d)=> setCategories(d.data))
    
  },[id])


  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardCategoriesCrud categories={categories} />
        </div>
      </div>
    </>
  );
}

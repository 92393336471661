import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter} from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";


import App from "./App.js";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDTlxZLcfukQNqD0DAPYoNIcHjls6NPz7w",
  authDomain: "energia-store.firebaseapp.com",
  projectId: "energia-store",
  storageBucket: "energia-store.appspot.com",
  messagingSenderId: "428490664188",
  appId: "1:428490664188:web:e0f208f420a9caac598f79",
  measurementId: "G-BQFW959N45"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


ReactDOM.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>,
  document.getElementById("root")
);

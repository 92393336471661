import React, { useEffect, useState } from "react";

// components

import axios from "axios";
import { useParams } from "react-router";
import CardProductSelfCrud from "components/Cards/CardProductSelfCrud/CardProductSelfCrud";


export default function ProductSelfCrud() {
  const [products, setProducts] = useState(null)
  const {id} = useParams()

  useEffect(()=>{
    if(id!=="create")axios.get(process.env.REACT_APP_API+"/productself/"+id)
    .then((d)=> setProducts(d.data))
  },[id])

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardProductSelfCrud products={products} />
        </div>
      </div>
    </>
  );
}
